import { useEffect, useState } from "react";
// import {
//   createSupportActions,
//   getSupportActions,
//   getSupportAdminActions,
// } from "../../../redux/Help&Support/actions";
import { useDispatch, useSelector } from "react-redux";
// import { baymetal } from "../img/images";
import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import {
  createTicket,
  getTicket,
} from "../../../redux/accountPro/Tickets/action";
import { ButtonLoading } from "../../../helpers/loadingMain";

const HelpSupport = ({ show, hide, file }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [attachment, setAttachment] = useState(null);
  const [description, setDescription] = useState("");
  const [issueType, setIssueType] = useState("");
  const [priorityType, setPriorityType] = useState("medium");
  const currentYear = new Date().getFullYear();
  const store = useSelector((state) => state);
  const user = store?.Auth?.user?.id;
  const Role = store?.Auth?.user?.role;

  const getCurrentPageInfo = () => {
    const currentPageURL = location.pathname;
    const currentPageName = currentPageURL
      .substring(currentPageURL.lastIndexOf("/") + 1)
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/\b\w/g, (c) => c.toUpperCase());
    return { currentPageURL, currentPageName };
  };

  // Function to convert Data URL to Blob
  function dataURLtoBlob(dataURL) {
    if (!dataURL) {
      return null; // Return null if dataURL is not provided
    }

    // Split the dataURL into metadata and base64-encoded data
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1].split(";")[0];
    const byteString = atob(parts[1]);

    // Create an ArrayBuffer and a Uint8Array to store binary data
    const buffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(buffer);

    // Convert each character in the byteString to its charCode and store in the Uint8Array
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the ArrayBuffer and return it
    return new Blob([buffer], { type: contentType });
  }
  const ticketData = store?.createTicketReducer?.data;
  console.log(store?.createTicketReducer, "ticketDataticketData");
  useEffect(() => {
    if (ticketData?.status == 200) {
      hide();
      setAttachment(null);
      setDescription("");
      setIssueType("");
    }
  }, [ticketData]);
  const onSubmitData = (e) => {
    e.preventDefault(); // Prevents default form submission behavior

    // Ensure file is not undefined before converting to blob
    const image = file ? dataURLtoBlob(file) : null;

    // Create FormData object and append data
    const formData = new FormData();
    formData.append("image", attachment || image);
    formData.append("description", description);
    formData.append("moduleName", getCurrentPageInfo().currentPageName);
    formData.append(
      "currentPageUrl",
      "https://client.accountspro.eu" + location.pathname
    );
    // formData.append("issueType", issueType);
    // formData.append("priority", priorityType);
    // formData.append("userId", user);

    // Dispatch action to create support
    dispatch(createTicket(formData));
    //   if (Role === "user") {
    //     dispatch(getSupportActions({ userId: user, status: "pending" }));
    //   } else {
    //     dispatch(
    //       getSupportAdminActions({
    //         limit: 20,
    //         pages: 0,
    //       })
    //     );
    //   }
    // Clear form fields and hide modal
  };

  const close = () => {
    hide();
    setAttachment(null);
    setDescription("");
    setIssueType("");
    // if (Role === "user") {
    //   dispatch(getSupportActions({ userId: user, status: "pending" }));
    // } else {
    //   dispatch(
    //     getSupportAdminActions({
    //       limit: 20,
    //       pages: 0,
    //     })
    //   );
    // }
  };

  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);

  // const modalStyle = {
  //     display: show ? 'block' : 'none',
  //     zIndex: 9999, // Ensure modal stays on top
  // };

  const handleContentClick = (e) => {
    e.stopPropagation(); // Stop propagation to prevent modal from closing
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={close}
        centered
        style={{ background: "#3b39399c" }}
      >
        <div className="modal-content" onMouseDown={handleContentClick}>
          <Modal.Header className="bg-info text-white py-0">
            <Modal.Title>Help & Support</Modal.Title>
            <i
              className="mdi mdi-close fs-3"
              onClick={close}
              style={{ cursor: "pointer" }}
            ></i>
          </Modal.Header>
          <Modal.Body className="py-1">
            <form onSubmit={onSubmitData}>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-7">
                      <div className="col-md-12 mt-2">
                        <h4>
                          Name:{" "}
                          <span className="text-success fw-bold">
                            {getCurrentPageInfo().currentPageName}
                          </span>
                        </h4>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="form-group d-flex">
                          <label className=" pe-2 fw-bold ">URL:</label>
                          <a href={location.pathname} title="view">
                            {location.pathname.slice(0, 42)}
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                      {/* {file ? (
                        <></>
                      ) : (
                        <span
                          onClick={handleShow2}
                          className="fw-bold fs-4 text-info"
                          title="more info"
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            transform: "translate(-8px, 50px)",
                          }}
                        >
                          ⓘ
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-2">
                  {file ? (
                    <div>
                      <label htmlFor="image">Attachment</label>
                      <img
                        src={file}
                        alt=""
                        className="w-50 h-50 border ms-2 p-1 bg-secondary"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label htmlFor="image">Attachment</label>
                      <input
                        type="file"
                        id="image"
                        className="form-control"
                        accept="image/*"
                        required
                        onChange={(e) => setAttachment(e.target.files[0])}
                      />
                    </div>
                  )}
                </div>
                {/* <div className="col-md-12 mt-2">
                  <div className="row">
                    <div className="col-6">
                      {" "}
                      <div className="form-group">
                        <label htmlFor="issueType">Issue Type</label>
                        <select
                          id="issueType"
                          className="form-control"
                          value={issueType}
                          required
                          onChange={(e) => setIssueType(e.target.value)}
                        >
                          <option value="" hidden>
                            Select Issue Type
                          </option>
                          <option value="bug">Bug</option>
                          <option value="recomended">Recomendation</option>
                          <option value="enhancement">Enhancement</option>
                          <option value="other">Other</option>
                        </select>
                        {issueType === "other" ? (
                          <span className="text-danger small">
                            Describe Below{" "}
                            <i className="mdi mdi-arrow-down-bold"></i>
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="priorityType">Priority</label>
                        <select
                          id="priorityType"
                          className="form-control"
                          value={priorityType}
                          required
                          onChange={(e) => setPriorityType(e.target.value)}
                        >
                          <option value="" hidden>
                            Select Priority Type
                          </option>
                          <option value="critical">Critical</option>
                          <option value="high">High</option>
                          <option value="medium">Medium</option>
                          <option value="low">Low</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 mt-2">
                  <div className="form-group">
                    <label htmlFor="description">
                      Tell us more about {issueType ? issueType : <>Issue</>}
                    </label>
                    <textarea
                      id="description"
                      className="form-control"
                      rows="3"
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mt-2 d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: "11px" }}>
                    {" "}
                    1969-{currentYear} Copyright © Bay Metal, Inc. All Rights
                    Reserved.{" "}
                  </span>
                  <Button type="submit" className="btn btn-primary">
                    {store?.createTicketReducer?.loading ? (
                      <ButtonLoading />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </Modal.Body>
          <ToastContainer />
        </div>
      </Modal>
    </div>
  );
};

export default HelpSupport;
