import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { Charges } from "./constant";
import { getChargesEndPoint } from "./api";

function* getChargesFunction(data) {
  try {
    yield put({
      type: Charges.GET_CHARGES_LOADING,
      payload: {},
    });
    const response = yield call(getChargesEndPoint, data);
    if (response.data.status) {
      yield put({
        type: Charges.GET_CHARGES_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: Charges.GET_CHARGES_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: Charges.GET_CHARGES_RESET,
      payload: error?.data,
    });
  }
}

export function* ChargesWatcher(): any {
  yield takeEvery(Charges.GET_CHARGES, getChargesFunction);
}

function* AllChargesSaga(): any {
  yield all([
    // fork(acctionAddRating),
    fork(ChargesWatcher),
  ]);
}
export default AllChargesSaga;
