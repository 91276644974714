import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { searchAccountantType } from "./constant";
import {
  generateContractApi,
  getAccountantDetailsApi,
  getCalendlyTokenEndPoint,
  getClientAccountantDetailsApi,
  getClientContractListEndPoint,
  getContractListEndPoint,
  getDocusignTokenEndPoint,
  getIndustoryEndPoint,
  getSentInvitationApi,
  postAccountStripeApi,
  searchAccountantApi,
  sendInvitationApi,
  updatePaymentMethodApi,
} from "./api";

function* searchAccountantFunction({ payload }) {
  try {
    yield put({
      type: searchAccountantType.SEARCH_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(searchAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: searchAccountantType.SEARCH_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      // yield put({
      //     type: searchAccountantType.SEARCH_ACCOUNTANT_RESET,
      //     payload: {},
      // });
    } else {
      yield put({
        type: searchAccountantType.SEARCH_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.SEARCH_ACCOUNTANT_ERROR,
      payload: error?.data,
    });
  }
}

function* getIndustoryFunction(action) {
  try {
    yield put({
      type: searchAccountantType.GET_LOADING_INDUSTORY,
      payload: {},
    });
    const response = yield call(getIndustoryEndPoint, action.payload.itaa);
    if (response.data.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_INDUSTORY,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_INDUSTORY,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_INDUSTORY,
      payload: error?.response?.data || error,
    });
  }
}

function* sendInvitationFunction({ payload }) {
  try {
    yield put({
      type: searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(sendInvitationApi, { payload });
    if (response.data.status) {
      yield put({
        type: searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_ERROR,
      payload: error?.data,
    });
  }
}

function* getCalendlyTokenFunction({ payload }) {
  try {
    yield put({
      type: searchAccountantType.GET_LOADING_CALENDLY_API_KEY,
      payload: {},
    });
    const response = yield call(getCalendlyTokenEndPoint, { payload });
    if (response?.data?.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_CALENDLY_API_KEY,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_CALENDLY_API_KEY,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_CALENDLY_API_KEY,
      payload: error?.response?.data || error,
    });
  }
}

function* getSentInvitationFunction(action) {
  try {
    yield put({
      type: searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(getSentInvitationApi, action);
    if (response.data.status) {
      yield put({
        type: searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      // yield put({
      //     type: searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_RESET,
      //     payload: {},
      // });
    } else {
      yield put({
        type: searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_ERROR,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_ERROR,
      payload: error?.response?.data || error,
    });
  }
}

function* sendAccountValidateStripeFunction({ payload }) {
  try {
    yield put({
      type: searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_LOADING,
      payload: {},
    });
    const response = yield call(postAccountStripeApi, { payload });
    if (response.data.status) {
      yield put({
        type: searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_ERROR,
      payload: error?.data,
    });
  }
}

function* getAccountantFunction(action) {
  try {
    yield put({
      type: searchAccountantType.GET_LOADING_ACCOUNTANT_DETAILS,
      payload: {},
    });
    const response = yield call(getAccountantDetailsApi, action);
    if (response.data.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_ACCOUNTANT_DETAILS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_ACCOUNTANT_DETAILS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_ACCOUNTANT_DETAILS,
      payload: error?.response?.data || error,
    });
  }
}

function* getAmountFunction(payload) {

  try {
    yield put({
      type: searchAccountantType.GET_LOADING_AMOUNT,
      payload: {},
    });

    // Ensure you're passing a string, not an object
    const subscriptionType = payload?.payload.subscriptionType; // Adjust based on your payload structure
    const response = yield call(getClientContractListEndPoint, subscriptionType);

    if (response.data.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_AMOUNT,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_AMOUNT,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_AMOUNT,
      payload: error?.response?.data || error,
    });
  }
}


function* getClientAccountantFunction(action) {
  try {
    yield put({
      type: searchAccountantType.GET_LOADING_CLIENT_ACCOUNTANT_DETAILS,
      payload: {},
    });
    const response = yield call(getClientAccountantDetailsApi, action);
    if (response.data.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_CLIENT_ACCOUNTANT_DETAILS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_CLIENT_ACCOUNTANT_DETAILS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_CLIENT_ACCOUNTANT_DETAILS,
      payload: error?.response?.data || error,
    });
  }
}

function* updatePaymentMethodFunction({ payload }) {
  try {
    yield put({
      type: searchAccountantType.UPDATE_PAYMENT_METHOD_LOADING__DETAILS,
      payload: {},
    });
    const response = yield call(updatePaymentMethodApi, { payload });
    if (response.data.status) {
      yield put({
        type: searchAccountantType.UPDATE_PAYMENT_METHOD_SUCCESS__DETAILS,
        payload: { ...response.data },
      });
      yield put({
        type: searchAccountantType.UPDATE_PAYMENT_METHOD_RESET__DETAILS,
        payload: {},
      });
    } else {
      yield put({
        type: searchAccountantType.UPDATE_PAYMENT_METHOD_ERROR__DETAILS,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.UPDATE_PAYMENT_METHOD_ERROR__DETAILS,
      payload: error?.data,
    });
  }
}
function* generateContractFunction({ payload }) {
  try {
    yield put({
      type: searchAccountantType.GENERATE_CONTRACT_LOADING__DETAILS,
      payload: {},
    });
    const response = yield call(generateContractApi, { payload });
    if (response.data.status) {
      yield put({
        type: searchAccountantType.GENERATE_CONTRACT_SUCCESS__DETAILS,
        payload: { ...response.data },
      });
      yield put({
        type: searchAccountantType.GENERATE_CONTRACT_RESET__DETAILS,
        payload: {},
      });
    } else {
      yield put({
        type: searchAccountantType.GENERATE_CONTRACT_ERROR__DETAILS,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GENERATE_CONTRACT_ERROR__DETAILS,
      payload: error?.data,
    });
  }
}

function* getDocusignTokenFunction(action) {
  try {
    yield put({
      type: searchAccountantType.GET_LOADING_DOCUSIGN_API_KEY,
      payload: {},
    });
    const response = yield call(getDocusignTokenEndPoint, action);
    if (response?.data?.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_DOCUSIGN_API_KEY,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_DOCUSIGN_API_KEY,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_DOCUSIGN_API_KEY,
      payload: error?.response?.data || error,
    });
  }
}

// function* getContractListFunction({ payload }) {
//     try {
//         yield put({
//             type: searchAccountantType.GET_LOADING_CONTRACT_LIST,
//             payload: {}
//         });
//         const response = yield call(getContractListEndPoint, { payload });
//         if (response?.data?.status) {
//             yield put({
//                 type: searchAccountantType.GET_SUCCESS_CONTRACT_LIST,
//                 payload: { ...response.data },
//             });
//         } else {
//             yield put({
//                 type: searchAccountantType.GET_ERROR_CONTRACT_LIST,
//                 payload: response.data,
//             });
//         }
//     } catch (error) {
//         yield put({
//             type: searchAccountantType.GET_ERROR_CONTRACT_LIST,
//             payload: error?.response?.data || error,
//         });
//     }
// }
function* getContractListFunction({ payload }) {
  try {
    const accessToken = localStorage.getItem("token");
    yield put({
      type: searchAccountantType.GET_LOADING_CONTRACT_LIST,
      payload: {},
    });

    const response = yield call(getContractListEndPoint, payload);

    if (response?.data?.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_CONTRACT_LIST,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_CONTRACT_LIST,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_CONTRACT_LIST,
      payload: error?.response?.data || error,
    });
  }
}

function* getClientContractListFunction({ payload }) {
  try {
    yield put({
      type: searchAccountantType.GET_LOADING_CLIENT_CONTRACT_LIST,
      payload: {},
    });
    const response = yield call(getClientContractListEndPoint, { payload });
    if (response?.data?.status) {
      yield put({
        type: searchAccountantType.GET_SUCCESS_CLIENT_CONTRACT_LIST,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: searchAccountantType.GET_ERROR_CLIENT_CONTRACT_LIST,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: searchAccountantType.GET_ERROR_CLIENT_CONTRACT_LIST,
      payload: error?.response?.data || error,
    });
  }
}

export function* searchAccountantSaga(): any {
  yield takeEvery(
    searchAccountantType.SEARCH_ACCOUNTANT,
    searchAccountantFunction
  );
}

export function* acctionGetIndustory(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_INDUSTORY,
    getIndustoryFunction
  );
}

export function* actionToSendInvitation(): any {
  yield takeEvery(
    searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT,
    sendInvitationFunction
  );
}

export function* actionGetCalendlyToken(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_CALENDLY_API_KEY,
    getCalendlyTokenFunction
  );
}
export function* actionGetSentInvitation(): any {
  yield takeEvery(
    searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT,
    getSentInvitationFunction
  );
}
export function* actionAccountStripe(): any {
  yield takeEvery(
    searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_,
    sendAccountValidateStripeFunction
  );
}
export function* actionGetAccountantDetails(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_ACCOUNTANT_DETAILS,
    getAccountantFunction
  );
}
export function* actionGetClientAccountantDetails(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_CLIENT_ACCOUNTANT_DETAILS,
    getClientAccountantFunction
  );
}
export function* actionUpdatePaymentMethod(): any {
  yield takeEvery(
    searchAccountantType.UPDATE_PAYMENT_METHOD_FIRST__DETAILS,
    updatePaymentMethodFunction
  );
}
export function* actionGenerateContract(): any {
  yield takeEvery(
    searchAccountantType.GENERATE_CONTRACT_FIRST__DETAILS,
    generateContractFunction
  );
}
export function* actionGetDocusignToken(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_DOCUSIGN_API_KEY,
    getDocusignTokenFunction
  );
}
export function* actionGetContractList(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_CONTRACT_LIST,
    getContractListFunction
  );
}
export function* actionGetClientContractList(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_CLIENT_CONTRACT_LIST,
    getClientContractListFunction
  );
}
export function* actionGetAmount(): any {
  yield takeEvery(
    searchAccountantType.GET_FIRST_AMOUNT,
    getAmountFunction
  );
}

function* accountantSearchAllSaga(): any {
  yield all([
    fork(searchAccountantSaga),
    fork(acctionGetIndustory),
    fork(actionToSendInvitation),
    fork(actionGetCalendlyToken),
    fork(actionGetSentInvitation),
    fork(actionAccountStripe),
    fork(actionGetAccountantDetails),
    fork(actionGetClientAccountantDetails),
    fork(actionUpdatePaymentMethod),
    fork(actionGenerateContract),
    fork(actionGetDocusignToken),
    fork(actionGetContractList),
    fork(actionGetClientContractList),
    fork(actionGetAmount),
  ]);
}

export default accountantSearchAllSaga;
