import { NotifectionTypes } from "./constant";

export const getNotifectionActions = (data) => ({
    type: NotifectionTypes.GET_NOTIFECTION_FIRST,
    data,
});

export const getNotifectionQountActions = (data) => ({
    type: NotifectionTypes.GET_NOTIFECTION_QOUNT_FIRST,
    data,
});
