export const USER_LOGIN = "/api/user/userLogin";
export const BUSINESS_EMAIL_OTP = "/api/user/emailVerify";
export const VERIFY_EMAIL_OTP = "/api/user/verifyOtp";
export const SUB_CLIENT_EMAIL_SEND_OTP = "/api/user/sendOtp?";
export const GET_COMPANIES_BY_SEARCH = "/api/user/getCompaniesBySearch?";
export const REGISTER_USER = "/api/user/registerUser";
export const COMPANY_CHECK_VERIFY = "/api/user/organizationCheck";
export const COMPANY_CREATE = "/api/onboard/companyRequest";
export const RESET_PASSWORD_EMAIL = "/api/user/forgotPassword";
export const RESET_PASSWORD_VERITY = "/api/user/changePassword";
export const UPDATE_PROFILE = "/api/user/clientUpdate";
export const Get_PROFILE = "/api/user/getClientProfile";
export const GET_ORGNISATION = "/api/organization/getUserOrganization?";
export const UPDATE_ORGNISATION = "/api/organization/updateUserOrganization";
export const UPDATE_PASSWORD = "/api/user/changePassword";
export const GET_SUBUSER = "/api/user/getClientsubUser";
export const ADD_SUBUSER = "/api/user/registerUser";
export const UPDATE_SUBUSER = "/api/user/clientUpdate";
export const DELETE_SUB_USER = "/api/user/subUserDelete?";
export const EMAIL_VERIFY_SEND = "/api/organization/organizationEmailVerify";
export const EMAIL_VERIFY_DELETE = "/api/organization/deleteMail?";
export const OTP_VERIFY_SEND = "/api/organization/otpVerify";
export const ACCOUNT_DETAIL_ACCOUNT = "/api/user/accountDetails";
export const MOBILE_NUMBER_VERIFY = "/api/user/phoneVerify";
export const MOBILE_NUMBER_VERIFY_OTP_SEND = "/api/user/verifyOtp";
export const GET_VAT_DETAILS_FOR_CLEINT = "/api/accountant/getVatDetails?";
export const SEARCH_ACCOUNTANT = "/api/accountant/accountantFind";
export const GET_INDUSTORY = "/api/accountant/getIndustry?";
export const SEND_INVITATION_TO_ACCOUNTANT =
  "/api/accountant/sendAccountantInvitation";
export const GET_CALENDLY_APITOKEN = "/auth";
export const GET_DOCUSIGN_APITOKEN = "/getAccessToken";

// ANNOUNCEMENTS

export const GET_ANNOUNCEMENT = "/api/announcement/clientAnnouncement";
// GET SEND INVITE ACCOUNTANT LIST
export const GET_SEND_INVITE_ACCOUNTANT_LIST =
  "/api/accountant/getSelectedAccountant";
// Stripe
export const POST_ACCOUNT_VALIDATE_STRIPE_SEPA =
  "/api/accountant/createPayment";
// Get Accountant Details
export const GET_ACCOUNTANT_DETAILS = "/api/accountant/getMyAccountant";
// Get Client Account
export const GET_CLIENT_ACCOUNTANT_DETAILS =
  "/api/accountant/getClientAccountInfo";
// Update Payment Method
export const UPDATE_PAYMENT_METHOD = "/api/accountant/updatePaymentMethod";
// Create Generate
export const GENERATE_CONTRACT = "/api/accountant/generateDocusignContract";
// Get Contract
export const GET_CONTRACT = "/api/accountant/getContractPageData";
// Get Client Contract
export const GET_CLIENT_CONTRACT = "/api/accountant/getClientContract";
export const ADD_RATING = "api/rating/createAccountantRating";
export const GET_RATING = "api/rating/getClientRating";
export const GET_NOTIFECTION = "api/notification/clientNotification";
export const GET_QOUNT_NOTIFECTION = "api/notification/newNotifications";
export const GET_AMOUNT = "api/accountant/fetchAmount";
// Get Charges
export const GET_CHARGES = "/api/master/getCharges";
export const APPROVE_PROVISIONAL = "/api/onboard/approveVat";
export const REJECT_VAT = "/api/onboard/rejectVat";
export const GET_TICKET = "/api/support/getTicket?status=";
export const GET_TICKET_BY_ID = "/api/support/getTicketById";
export const CREATE_TICKET = "/api/support/createTicket";
export const UPDATE_TICKET = "/api/support/updateStatus";
