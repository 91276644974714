import { BrowserRouter, useLocation } from "react-router-dom";
import { AllRoutes } from "./index";
import ActionButton from "../components/ActionButton/ActionButton";
import { useEffect, useState } from "react";

const Routes = () => {
  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
};

export default Routes;
