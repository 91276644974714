import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { RatingTypes } from "./constant";
import { addRatingEndPoint, getRatingEndPoint } from "./api";

function* addRatingFunction(data) {
    try {
        yield put({
            type: RatingTypes.ADD_RATING_LOADING,
            payload: {},
        });
        const response = yield call(addRatingEndPoint, data);
        if (response.data.status) {
            yield put({
                type: RatingTypes.ADD_RATING_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: RatingTypes.ADD_RATING_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: RatingTypes.ADD_RATING_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: RatingTypes.ADD_RATING_ERROR,
            payload: error?.data,
        });
        yield put({
            type: RatingTypes.ADD_RATING_RESET,
            payload: {},
        });
    }
}

function* getRatingFunction(data) {
    try {
        yield put({
            type: RatingTypes.GET_RATING_LOADING,
            payload: {},
        });
        const response = yield call(getRatingEndPoint, data);
        // console.log(data, "00000000987654");
        if (response.data.status) {
            yield put({
                type: RatingTypes.GET_RATING_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: RatingTypes.GET_RATING_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: RatingTypes.GET_RATING_RESET,
            payload: error?.data,
        });
        // yield put({
        //     type: RatingTypes.GET_RATING_RESET,
        //     payload: {},
        // });
    }
}

export function* acctionAddRating(): any {
    yield takeEvery(
        RatingTypes.ADD_RATING_FIRST,
        addRatingFunction
    );
}
export function* acctionGetRating(): any {
    yield takeEvery(
        RatingTypes.GET_RATING_FIRST,
        getRatingFunction
    );
}

function* AllRatingSaga(): any {
    yield all([
        fork(acctionAddRating),
        fork(acctionGetRating),
    ]);
}
export default AllRatingSaga;
